import axios from "axios";

export default {
  methods: {
    async download_blob(dl_url, type, data, name) {
      let response;
      if (type == "post") {
        response = await axios.post(dl_url, data, {
          responseType: "blob",
        });
      } else {
        response = await axios.get(dl_url, { responseType: "blob" });
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async aws_presigned_post(filename) {
      let { data } = await axios.post(
        "https://api.sendmore.com/aws_presigned_post",
        // "http://127.0.0.1:5000/hasura/aws_presigned_post",
        {
          input: { filename },
        }
      );
      console.log(data);
      return data;
    },
    async dl_event_list(event_id) {
      await this.download_blob(
        `https://api.sendmore.com/event_list/${event_id}`,
        "get",
        {},
        `event_${event_id}.xlsx`
      );
    },
    async overflow_list(seminar_id) {
      await this.download_blob(
        `https://reports-annuitycheck-com.herokuapp.com/deliverables/overflow_list`,
        "post",
        { seminar_id: seminar_id },
        "Overflow List.pdf"
      );
    },
    async mailer(seminar_id) {
      await this.download_blob(
        `https://templates.sendmore.com/seminar_helper/${seminar_id}`,
        "get",
        {},
        "Mailer.pdf"
      );
    },
    async registration_cards(seminar_id, event_id, time_slots) {
      await this.download_blob(
        `https://reports-annuitycheck-com.herokuapp.com/deliverables/registration_cards`,
        "post",
        {
          seminar_id,
          event_id,
          time_slots,
        },
        "Appointment Cards.pdf"
      );
    },
    async registration_list(seminar_id, event_id) {
      await this.download_blob(
        "https://reports-annuitycheck-com.herokuapp.com/deliverables/registration_list",
        "post",
        {
          seminar_id,
          event_id,
        },
        "Registration List.pdf"
      );
    },
    async nameplate_list(seminar_id, event_id) {
      await this.download_blob(
        "https://reports-annuitycheck-com.herokuapp.com/deliverables/nameplate_list",
        "post",
        {
          seminar_id,
          event_id,
        },
        "Avery Nameplate List.pdf"
      );
    },
    async appointment_card(seminar_id, event_id) {
      await this.download_blob(
        `https://reports-annuitycheck-com.herokuapp.com/deliverables/appointment_cards/${seminar_id}`,
        "post",
        {
          seminar_id,
          event_id,
        },
        "Registration List.pdf"
      );
    },
    // async report_download(data) {},
    async get_accudata_count(zips, income, minage, maxage) {
      // Data should look like this:
      // {"zips":["85201"],"income":"75000","distance":"10","minage":"60","maxage":"75"}

      return await axios.post(
        "https://api-accudata.webinarlabs.com/misc/accudata/count_zip",
        {
          zips,
          income,
          distance: 0, // distance
          minage,
          maxage,
        }
      );
    },
  },
};
